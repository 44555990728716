<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App'
})
</script>
<style lang="css">
  @import "./assets/css/custom-style.css";
</style>