
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/dashboard",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "_self",
    "submenu": []
  },
  {
    "path": "/home",
    "label": "Dashboard Lead",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "",
    "label": "Admin Area",
    "icon": "network_locked",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/usuarios",
        "label": "Users",
        "icon": "face",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/roles",
        "label": "Roles",
        "icon": "format_align_justify",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/permissions",
        "label": "Permissions",
        "icon": "folder",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/audi",
        "label": "Audit",
        "icon": "folder_shared",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "",
    "label": "Configuration",
    "icon": "perm_data_setting",
    "iconcolor": "",
    "target": "_parent",
    "submenu": [
      {
        "path": "/mediopago",
        "label": "Payment method",
        "icon": "account_balance",
        "iconcolor": "",
        "target": "_self",
        "submenu": []
      },
      {
        "path": "/marketing",
        "label": "Marketing Source",
        "icon": "smartphone",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tipocaso",
        "label": "Type of Case",
        "icon": "add_to_photos",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/idioma",
        "label": "Language",
        "icon": "burst_mode",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/genero",
        "label": "Gender",
        "icon": "wc",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/estadocaso",
        "label": "Case Status",
        "icon": "view_list",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/empresas",
        "label": "Companies",
        "icon": "local_convenience_store",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/representantelegal",
        "label": "Legal Representative",
        "icon": "pan_tool",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/potencialesclientes",
    "label": "Leads",
    "icon": "person_outline",
    "iconcolor": "",
    "target": "_self",
    "submenu": []
  },
  {
    "path": "/potencialesclientes/add",
    "label": "Add Leads",
    "icon": "person_add",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	applogslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Log Id",
    "align": "left",
    "sortable": false,
    "name": "log_id",
    "field": "log_id"
  },
  {
    "label": "Timestamp",
    "align": "left",
    "sortable": false,
    "name": "timestamp",
    "field": "timestamp"
  },
  {
    "label": "Action",
    "align": "left",
    "sortable": false,
    "name": "action",
    "field": "action"
  },
  {
    "label": "Tablename",
    "align": "left",
    "sortable": false,
    "name": "tablename",
    "field": "tablename"
  },
  {
    "label": "Recordid",
    "align": "left",
    "sortable": false,
    "name": "recordid",
    "field": "recordid"
  },
  {
    "label": "Sqlquery",
    "align": "left",
    "sortable": false,
    "name": "sqlquery",
    "field": "sqlquery"
  },
  {
    "label": "Userid",
    "align": "left",
    "sortable": false,
    "name": "userid",
    "field": "userid"
  },
  {
    "label": "Serverip",
    "align": "left",
    "sortable": false,
    "name": "serverip",
    "field": "serverip"
  },
  {
    "label": "Requesturl",
    "align": "left",
    "sortable": false,
    "name": "requesturl",
    "field": "requesturl"
  },
  {
    "label": "Requestdata",
    "align": "left",
    "sortable": false,
    "name": "requestdata",
    "field": "requestdata"
  },
  {
    "label": "Requestcompleted",
    "align": "left",
    "sortable": false,
    "name": "requestcompleted",
    "field": "requestcompleted"
  },
  {
    "label": "Requestmsg",
    "align": "left",
    "sortable": false,
    "name": "requestmsg",
    "field": "requestmsg"
  },
  {
    "label": "Page",
    "align": "left",
    "sortable": false,
    "name": "page",
    "field": "page"
  },
  {
    "label": "Record Id",
    "align": "left",
    "sortable": false,
    "name": "record_id",
    "field": "record_id"
  },
  {
    "label": "Old Values",
    "align": "left",
    "sortable": false,
    "name": "old_values",
    "field": "old_values"
  },
  {
    "label": "New Values",
    "align": "left",
    "sortable": false,
    "name": "new_values",
    "field": "new_values"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "User Ip",
    "align": "left",
    "sortable": false,
    "name": "user_ip",
    "field": "user_ip"
  },
  {
    "label": "User Agent",
    "align": "left",
    "sortable": false,
    "name": "user_agent",
    "field": "user_agent"
  },
  {
    "label": "Request Url",
    "align": "left",
    "sortable": false,
    "name": "request_url",
    "field": "request_url"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	audilistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Type",
    "align": "left",
    "sortable": false,
    "name": "user_type",
    "field": "user_type"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Event",
    "align": "left",
    "sortable": false,
    "name": "event",
    "field": "event"
  },
  {
    "label": "Auditable Type",
    "align": "left",
    "sortable": false,
    "name": "auditable_type",
    "field": "auditable_type"
  },
  {
    "label": "Auditable Id",
    "align": "left",
    "sortable": false,
    "name": "auditable_id",
    "field": "auditable_id"
  },
  {
    "label": "Old Values",
    "align": "left",
    "sortable": false,
    "name": "old_values",
    "field": "old_values"
  },
  {
    "label": "New Values",
    "align": "left",
    "sortable": false,
    "name": "new_values",
    "field": "new_values"
  },
  {
    "label": "Url",
    "align": "left",
    "sortable": false,
    "name": "url",
    "field": "url"
  },
  {
    "label": "Ip Address",
    "align": "left",
    "sortable": false,
    "name": "ip_address",
    "field": "ip_address"
  },
  {
    "label": "User Agent",
    "align": "left",
    "sortable": false,
    "name": "user_agent",
    "field": "user_agent"
  },
  {
    "label": "Tags",
    "align": "left",
    "sortable": false,
    "name": "tags",
    "field": "tags"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	empresaslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Identification",
    "align": "left",
    "sortable": false,
    "name": "identificacion",
    "field": "identificacion"
  },
  {
    "label": "Social Reason",
    "align": "left",
    "sortable": false,
    "name": "razonsocial",
    "field": "razonsocial"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "direccion",
    "field": "direccion"
  },
  {
    "label": "Mobile Phone",
    "align": "left",
    "sortable": false,
    "name": "telefonomovil",
    "field": "telefonomovil"
  },
  {
    "label": "Office Phone",
    "align": "left",
    "sortable": false,
    "name": "telefonooficina",
    "field": "telefonooficina"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Contact",
    "align": "left",
    "sortable": false,
    "name": "contacto",
    "field": "contacto"
  },
  {
    "label": "Contact Phone",
    "align": "left",
    "sortable": false,
    "name": "telfcontacto",
    "field": "telfcontacto"
  },
  {
    "label": "Contact email",
    "align": "left",
    "sortable": false,
    "name": "emailcontacto",
    "field": "emailcontacto"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	empresausuarioslistheader: [
  {
    "label": "Usuario",
    "align": "left",
    "sortable": false,
    "name": "usuario",
    "field": "usuario"
  },
  {
    "label": "Idempresa",
    "align": "left",
    "sortable": false,
    "name": "idempresa",
    "field": "idempresa"
  },
  {
    "label": "Razonsocial",
    "align": "left",
    "sortable": false,
    "name": "razonsocial",
    "field": "razonsocial"
  }
],
	estadocasolistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Case Status",
    "align": "left",
    "sortable": false,
    "name": "estado",
    "field": "estado"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	generolistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "genero",
    "field": "genero"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	idiomalistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Language",
    "align": "left",
    "sortable": false,
    "name": "idioma",
    "field": "idioma"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	leadsmarketinglistheader: [
  {
    "label": "Marketing",
    "align": "left",
    "sortable": false,
    "name": "marketing",
    "field": "marketing"
  },
  {
    "label": "Tipocaso",
    "align": "left",
    "sortable": false,
    "name": "tipocaso",
    "field": "tipocaso"
  },
  {
    "label": "Estadocaso",
    "align": "left",
    "sortable": false,
    "name": "estadocaso",
    "field": "estadocaso"
  },
  {
    "label": "Empresa",
    "align": "left",
    "sortable": false,
    "name": "empresa",
    "field": "empresa"
  },
  {
    "label": "Ntipocaso",
    "align": "left",
    "sortable": false,
    "name": "ntipocaso",
    "field": "ntipocaso"
  },
  {
    "label": "Nmarketing",
    "align": "left",
    "sortable": false,
    "name": "nmarketing",
    "field": "nmarketing"
  },
  {
    "label": "Nestado",
    "align": "left",
    "sortable": false,
    "name": "nestado",
    "field": "nestado"
  },
  {
    "label": "Fecha",
    "align": "left",
    "sortable": false,
    "name": "fecha",
    "field": "fecha"
  }
],
	leadsphonelistheader: [
  {
    "label": "Nombres",
    "align": "left",
    "sortable": false,
    "name": "nombres",
    "field": "nombres"
  },
  {
    "label": "Apellidos",
    "align": "left",
    "sortable": false,
    "name": "apellidos",
    "field": "apellidos"
  },
  {
    "label": "Telefono",
    "align": "left",
    "sortable": false,
    "name": "telefono",
    "field": "telefono"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  }
],
	learscustomerlistheader: [
  {
    "label": "Fecha",
    "align": "left",
    "sortable": false,
    "name": "fecha",
    "field": "fecha"
  },
  {
    "label": "Leards",
    "align": "left",
    "sortable": false,
    "name": "leards",
    "field": "leards"
  },
  {
    "label": "Telefono",
    "align": "left",
    "sortable": false,
    "name": "telefono",
    "field": "telefono"
  },
  {
    "label": "Tipocaso",
    "align": "left",
    "sortable": false,
    "name": "tipocaso",
    "field": "tipocaso"
  },
  {
    "label": "Estado",
    "align": "left",
    "sortable": false,
    "name": "estado",
    "field": "estado"
  }
],
	marketinglistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Marketing",
    "align": "left",
    "sortable": false,
    "name": "marketing",
    "field": "marketing"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Is Deleted",
    "align": "left",
    "sortable": false,
    "name": "is_deleted",
    "field": "is_deleted"
  }
],
	mediopagolistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Payment method",
    "align": "left",
    "sortable": false,
    "name": "modopago",
    "field": "modopago"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Is Deleted",
    "align": "left",
    "sortable": false,
    "name": "is_deleted",
    "field": "is_deleted"
  }
],
	permissionslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Permission",
    "align": "left",
    "sortable": false,
    "name": "permission",
    "field": "permission"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	petencialesnotaslistheader: [],
	potencialesclienteslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "fecha",
    "field": "fecha"
  },
  {
    "label": "User",
    "align": "left",
    "sortable": false,
    "name": "usuarioregistro",
    "field": "usuarioregistro"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "nombres",
    "field": "nombres"
  },
  {
    "label": "Last Name",
    "align": "left",
    "sortable": false,
    "name": "apellidos",
    "field": "apellidos"
  },
  {
    "label": "Phone",
    "align": "left",
    "sortable": false,
    "name": "telefono",
    "field": "telefono"
  },
  {
    "label": "Type of Case",
    "align": "left",
    "sortable": false,
    "name": "tipocaso_tipocaso",
    "field": "tipocaso_tipocaso"
  },
  {
    "label": "Case Status",
    "align": "left",
    "sortable": false,
    "name": "estadocaso_estado",
    "field": "estadocaso_estado"
  }
],
	potencialesclientesleardscustomerheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	potencialesclientesleadsprocessheader: [
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "fecha",
    "field": "fecha"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "nombres",
    "field": "nombres"
  },
  {
    "label": "Last Name",
    "align": "left",
    "sortable": false,
    "name": "apellidos",
    "field": "apellidos"
  },
  {
    "label": "Phone",
    "align": "left",
    "sortable": false,
    "name": "telefono",
    "field": "telefono"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Type of Case",
    "align": "left",
    "sortable": false,
    "name": "tipocaso_tipocaso",
    "field": "tipocaso_tipocaso"
  },
  {
    "label": "Status Case",
    "align": "left",
    "sortable": false,
    "name": "estadocaso_estado",
    "field": "estadocaso_estado"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	representantelegallistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Charge",
    "align": "left",
    "sortable": false,
    "name": "cargo",
    "field": "cargo"
  },
  {
    "label": "Phone",
    "align": "left",
    "sortable": false,
    "name": "telefono",
    "field": "telefono"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "First Name",
    "align": "left",
    "sortable": false,
    "name": "nombres",
    "field": "nombres"
  },
  {
    "label": "Last Name",
    "align": "left",
    "sortable": false,
    "name": "apellidos",
    "field": "apellidos"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	roleslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Role Name",
    "align": "left",
    "sortable": false,
    "name": "role_name",
    "field": "role_name"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	tipocasolistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Type of Case",
    "align": "left",
    "sortable": false,
    "name": "tipocaso",
    "field": "tipocaso"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  }
],
	usuarioempresalistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Empresa",
    "align": "left",
    "sortable": false,
    "name": "empresa",
    "field": "empresa"
  },
  {
    "label": "Usuario",
    "align": "left",
    "sortable": false,
    "name": "usuario",
    "field": "usuario"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Is Deleted",
    "align": "left",
    "sortable": false,
    "name": "is_deleted",
    "field": "is_deleted"
  }
],
	usuarioslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "foto",
    "field": "foto"
  },
  {
    "label": "Account Status",
    "align": "left",
    "sortable": false,
    "name": "account_status",
    "field": "account_status"
  },
  {
    "label": "User Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_role_id",
    "field": "user_role_id"
  },
  {
    "label": "First Name",
    "align": "left",
    "sortable": false,
    "name": "nombres",
    "field": "nombres"
  },
  {
    "label": "Last Name",
    "align": "left",
    "sortable": false,
    "name": "apellidos",
    "field": "apellidos"
  },
  {
    "label": "Cellphone",
    "align": "left",
    "sortable": false,
    "name": "telefono",
    "field": "telefono"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Is Deleted",
    "align": "left",
    "sortable": false,
    "name": "is_deleted",
    "field": "is_deleted"
  },
  {
    "label": "Companies",
    "align": "left",
    "sortable": false,
    "name": "empresas",
    "field": "empresas"
  }
],
	usuariosglobaleslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Usuario",
    "align": "left",
    "sortable": false,
    "name": "usuario",
    "field": "usuario"
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}