import script from "./FilterTags.vue?vue&type=script&setup=true&lang=js"
export * from "./FilterTags.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QChip});
